import useIsServer from "@/Hooks/useIsServer";
import { Transition } from "@headlessui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link, router } from "@inertiajs/react";
import { Fragment, useEffect, useRef, useState } from "react";

const Header = () => {
    const isServer = useIsServer();
    const [logoSize, setLogoSize] = useState<number>(250);
    const [hideTopBar, setHideTopBar] = useState<boolean>(false);
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
    const [showAdditionalMenu, setShowAdditionalMenu] = useState<boolean>(false);
    const [showCompanyMobileMenu, setShowCompanyMobileMenu] = useState<boolean>(false);
    const investmentRef = useRef<HTMLAnchorElement>(null);
    const locationRef = useRef<HTMLAnchorElement>(null);
    const investorRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        const throttle = <T extends (...args: any[]) => void>(func: T, limit: number) => {
            let inThrottle: boolean;
            return function (this: any, ...args: Parameters<T>) {
                const context = this;
                if (!inThrottle) {
                    func.apply(context, args);
                    inThrottle = true;
                    setTimeout(() => (inThrottle = false), limit);
                }
            };
        };

        const handleScrollThrottled = throttle(() => {
            const scrollY = window.scrollY;
            const threshold = 300;

            if (scrollY > threshold) {
                if (logoSize !== 160) {
                    setLogoSize(160);
                    setHideTopBar(true);
                }
            } else {
                if (logoSize !== 250) {
                    setLogoSize(250);
                    setHideTopBar(false);
                }
            }
        }, 30);

        if (!isServer) {
            window.addEventListener('scroll', handleScrollThrottled);
        }

        return () => {
            if (!isServer) {
                window.removeEventListener('scroll', handleScrollThrottled);
            }
        };
    }, [logoSize, isServer]);

    const handleHrefLink = (location: string) => {
        if (!isServer) {
            investmentRef.current?.classList.remove('active-menu-item');
            locationRef.current?.classList.remove('active-menu-item');
            investorRef.current?.classList.remove('active-menu-item');


            switch (location) {
                case 'o-inwestycji':
                    investmentRef.current?.classList.add('active-menu-item')
                    break;
                case 'lokalizacja':
                    locationRef.current?.classList.add('active-menu-item')
                    break;
                case 'inwestor':
                    investorRef.current?.classList.add('active-menu-item')
                    break;
            }

            const scrollTo = document.getElementById(location);
            setShowMobileMenu(false)

            if (scrollTo) {
                scrollTo.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
            }

            history.pushState(null, '', `#${location}`);
        }
    }

    const resolveActiveHashLink = (expectedItem: string): string => {
        if (!isServer) {
            if (window.location.hash == expectedItem && expectedItem !== '#kontakt') {
                return 'active-menu-item'
            } else {
                return '';
            }
        }

        return ''
    }

    return (
        <header className='fixed top-0 z-50 w-full'>
            <Transition
                show={!hideTopBar}
                as={'div'}
                enter="ease-out duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="bg-[#F2F6F9] 2xl:pr-14 lg:pr-6 mb:px-8 pl-3 lg:block hidden">
                    <div className="flex gap-x-5 justify-end items-center bigLg:hidden">
                        <div className="relative" onMouseEnter={() => setShowAdditionalMenu(true)} onMouseLeave={() => setShowAdditionalMenu(false)}>
                            <div title='O firmie' className='flex gap-x-2 text-gray1 text-label leading-label hover:bg-primary hover:text-white transition-all ease-in-out duration-150 py-[18.5px] px-4'>
                                <Icon icon="material-symbols:info" width={24} className='' />
                                O firmie
                            </div>
                            <Transition
                                show={showAdditionalMenu}
                                as={'div'}
                            >
                                <div className="absolute -bottom-[248px] z-50 min-w-[180px] w-full left-1/2 -translate-x-1/2">
                                    <ul>
                                        <li className="text-gray1 text-label leading-label bg-[#F2F6F9] hover:bg-primary hover:text-white transition-all ease-in-out duration-150 text-center">
                                            <a target="_blank" className="py-4 px-3 block" href="https://unidevelopment.pl/unidevelopment-sa-polski-deweloper">Unidevelopment</a>
                                        </li>
                                        <li className="text-gray1 text-label leading-label bg-[#F2F6F9] hover:bg-primary hover:text-white transition-all ease-in-out duration-150 text-center">
                                            <a target="_blank" className="py-4 px-3 block" href="https://unidevelopment.pl/node/161">Zespół</a>
                                        </li>
                                        <li className="text-gray1 text-label leading-label bg-[#F2F6F9] hover:bg-primary hover:text-white transition-all ease-in-out duration-150 text-center">
                                            <a target="_blank" className="py-4 px-3 block" href="https://unidevelopment.pl/grupa-unibep">Grupa Unibep</a>
                                        </li>
                                        <li className="text-gray1 text-label leading-label bg-[#F2F6F9] hover:bg-primary hover:text-white transition-all ease-in-out duration-150 text-center">
                                            <a target="_blank" className="py-4 px-3 block" href="https://unidevelopment.pl/nagrody-i-wyroznienia">Nagrody i wyróżnienia</a>
                                        </li>
                                    </ul>
                                </div>
                            </Transition>
                        </div>
                        <a href='https://unidevelopment.pl/relacje-inwestorskie' target="_blank" title='Relacje inwestorskie' className='flex gap-x-2 text-gray1 text-label leading-label hover:bg-primary hover:text-white transition-all ease-in-out duration-150 py-[18.5px] px-4'>
                            <Icon icon="fluent:people-20-filled" width={24} className='' />
                            Relacje inwestorskie
                        </a>
                        <a href='https://pl-pl.facebook.com/Unidevelopment/' target="_blank" title='Facebook' className='flex gap-x-2 text-gray1 py-[18.5px] px-4 '>
                            <Icon icon="brandico:facebook-rect" width={24} className='' />
                        </a>
                        <a href='https://www.youtube.com/c/UnidevelopmentWarszawa' target="_blank" title='Youtube' className='flex gap-x-2 text-gray1'>
                            <Icon icon="mingcute:youtube-fill" width={29} className='' />
                        </a>
                    </div>
                </div>
                <div className="hidden bigLg:block bg-[#F2F6F9]">
                    <div className="px-4">
                        <div className="flex justify-between items-center py-1">
                            <div>
                                <button type="button" className="flex gap-x-1" onClick={() => setShowAdditionalMenu((prev) => !prev)}>
                                    <span className="w-[6px] h-[6px] rounded-full bg-text"></span>
                                    <span className="w-[6px] h-[6px] rounded-full bg-text"></span>
                                    <span className="w-[6px] h-[6px] rounded-full bg-text"></span>
                                </button>
                            </div>
                            <div className="flex gap-x-6 items-center">
                                <a href='https://pl-pl.facebook.com/Unidevelopment/' target="_blank" title='Facebook' className='flex gap-x-2 text-gray1'>
                                    <Icon icon="brandico:facebook-rect" width={24} className='' />
                                </a>
                                <a href='https://www.youtube.com/c/UnidevelopmentWarszawa' target="_blank" title='Youtube' className='flex gap-x-2 text-gray1'>
                                    <Icon icon="mingcute:youtube-fill" width={32} className='' />
                                </a>
                            </div>
                        </div>
                        <Transition
                            show={showAdditionalMenu}
                            as={'div'}
                            enter="ease-out duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex flex-wrap mt-1 py-1 justify-between">
                                <button onClick={() => setShowCompanyMobileMenu((prev) => !prev)} title='O firmie' className='flex gap-x-2 text-gray1 text-label leading-label hover:bg-primary hover:text-white transition-all ease-in-out duration-150 py-1'>
                                    <Icon icon="material-symbols:info" width={24} className='' />
                                    O firmie
                                </button>
                                <a href='https://unidevelopment.pl/relacje-inwestorskie' target="_blank" title='Relacje inwestorskie' className='flex gap-x-2 text-gray1 text-label leading-label hover:bg-primary hover:text-white transition-all ease-in-out duration-150 py-1'>
                                    <Icon icon="fluent:people-20-filled" width={24} className='' />
                                    Relacje inwestorskie
                                </a>
                                <Transition
                                    show={showCompanyMobileMenu}
                                    as={Fragment}
                                    enter="ease-out duration-150"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-150"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="w-full py-2 text-center mx-auto">
                                        <ul>
                                            <li className="text-gray1 text-label leading-label bg-[#F2F6F9] hover:bg-primary hover:text-white transition-all ease-in-out duration-150 text-center">
                                                <a target="_blank" className="py-3 px-3 block" href="https://unidevelopment.pl/unidevelopment-sa-polski-deweloper">O firmie</a>
                                            </li>
                                            <li className="text-gray1 text-label leading-label bg-[#F2F6F9] hover:bg-primary hover:text-white transition-all ease-in-out duration-150 text-center">
                                                <a target="_blank" className="py-3 px-3 block" href="https://unidevelopment.pl/unidevelopment-sa-polski-deweloper">Unidevelopment</a>
                                            </li>
                                            <li className="text-gray1 text-label leading-label bg-[#F2F6F9] hover:bg-primary hover:text-white transition-all ease-in-out duration-150 text-center">
                                                <a target="_blank" className="py-3 px-3 block" href="https://unidevelopment.pl/node/161">Zespół</a>
                                            </li>
                                            <li className="text-gray1 text-label leading-label bg-[#F2F6F9] hover:bg-primary hover:text-white transition-all ease-in-out duration-150 text-center">
                                                <a target="_blank" className="py-3 px-3 block" href="https://unidevelopment.pl/grupa-unibep">Grupa Unibep</a>
                                            </li>
                                            <li className="text-gray1 text-label leading-label bg-[#F2F6F9] hover:bg-primary hover:text-white transition-all ease-in-out duration-150 text-center">
                                                <a target="_blank" className="py-3 px-3 block" href="https://unidevelopment.pl/nagrody-i-wyroznienia">Nagrody i wyróżnienia</a>
                                            </li>
                                        </ul>
                                    </div>
                                </Transition>
                            </div>
                        </Transition>
                    </div>
                </div>
            </Transition>
            <div className="md:hidden block py-2 bg-[#F2F6F9]">
                <a href="https://unidevelopment.pl/" target="_blank" title="Unidevelopment">
                    <img src="/storage/static/unidevelopment-logo.svg" alt="Logo Unidevelopment" className="max-w-[249px] w-full h-auto mx-auto" width={249} height={32} />
                </a>
            </div>
            <div className='header-bg 2xl:px-14 lg:px-6 mb:px-8 py-4 gap-x-6 bigLg:hidden items-center justify-between flex'>
                <Link title='Strona główna' href='/'>
                    <img src="/storage/static/kusocinskiego-logo.svg" alt="Logo Kusocińskiego" width={logoSize} height={96} className="transition-all ease-in-out duration-150 lg:max-w-[120px] mb:max-w-[180px] 2xl:max-w-[250px]" />
                </Link>
                <nav>
                    <ul className='flex 2xl:gap-x-8 lg:gap-x-4'>
                        <li>
                            <a ref={investmentRef} href="/#o-inwestycji" type="button" title='O inwestycji' onClick={() => handleHrefLink('o-inwestycji')} className={`tracking-a lg:text-[15px] 2xl:text-[16px] leading-label uppercase font-bold text-primary ${resolveActiveHashLink('#o-inwestycji')}`}>
                                O inwestycji
                            </a>
                        </li>
                        <li>
                            <Link href={route('frontend.apartments')} title='Galeria' className={`tracking-a lg:text-[15px] 2xl:text-[16px] leading-label uppercase font-bold text-primary ${(route().current() === 'frontend.apartments' || route().current() === 'frontend.apartments.plan' || route().current() === 'frontend.apartments.details') ? 'active-menu-item' : ''}`}>
                                Mieszkania
                            </Link>
                        </li>
                        <li>
                            <a ref={locationRef} href="/#lokalizacja" type="button" onClick={() => handleHrefLink('lokalizacja')} title='Lokalizacja' className={`tracking-a lg:text-[15px] 2xl:text-[16px] leading-label uppercase font-bold text-primary ${resolveActiveHashLink('#lokalizacja')}`}>
                                Lokalizacja
                            </a>
                        </li>
                        <li>
                            <Link href={route('frontend.gallery')} title='Galeria' className={`tracking-a lg:text-[15px] 2xl:text-[16px] leading-label uppercase font-bold text-primary ${(route().current() === 'frontend.gallery' || route().current() === 'frontend.gallery.single') ? 'active-menu-item' : ''}`}>
                                Galeria
                            </Link>
                        </li>
                        <li>
                            <Link href={route('frontend.creditService')} title='Finansowanie' className={`tracking-a lg:text-[15px] 2xl:text-[16px] leading-label uppercase font-bold text-primary ${(route().current() === 'frontend.creditService' || route().current() === 'frontend.creditService') ? 'active-menu-item' : ''}`}>
                                Finansowanie
                            </Link>
                        </li>
                        <li>
                            <Link href={route('frontend.interiors')} title='Wnętrza' className={`tracking-a lg:text-[15px] 2xl:text-[16px] leading-label uppercase font-bold text-primary ${(route().current() === 'frontend.interiors') ? 'active-menu-item' : ''}`}>
                                Wnętrza
                            </Link>
                        </li>
                        <li>
                            <Link href={route('frontend.news')} title='Galeria' className={`tracking-a lg:text-[15px] 2xl:text-[16px] leading-label uppercase font-bold text-primary ${(route().current() === 'frontend.news' || route().current() === 'frontend.news.single') ? 'active-menu-item' : ''}`}>
                                Aktualności
                            </Link>
                        </li>
                        <li>
                            <button type="button" onClick={() => handleHrefLink('kontakt')} title='Galeria' className={`tracking-a lg:text-[15px] 2xl:text-[16px] leading-label uppercase font-bold text-primary ${resolveActiveHashLink('#kontakt')}`}>
                                Kontakt
                            </button>
                        </li>
                    </ul>
                </nav>
                <a target="_blank" title='Unidevelopment' href='https://unidevelopment.pl/'>
                    <img src="/storage/static/unidevelopment-logo.svg" alt="Logo Unidevelopment" className="lg:max-w-[170px] mb:max-w-[185px] 2xl:max-w-[324px]" width={324} height={48} />
                </a>
            </div>
            <div className="header-bg hidden bigLg:flex p-4 justify-between items-center">
                <Link href="/" title="Strona główna">
                    <img src="/storage/static/kusocinskiego-logo.svg" alt="Logo Kusocińskiego" height={40} className="transition-all ease-in-out duration-150 h-10 w-auto" />
                </Link>
                <a href="https://unidevelopment.pl/" target="_blank" title="Unidevelopment" className="md:block minBigLg:hidden hidden">
                    <img src="/storage/static/unidevelopment-logo.svg" alt="Logo Unidevelopment" className="max-w-[249px] w-full h-auto mx-auto" width={249} height={32} />
                </a>
                <button type="button" onClick={() => setShowMobileMenu((prev) => !prev)}>
                    {!showMobileMenu ?
                        <Icon icon={`bi:list`} width={24} className="text-primary" />
                        :
                        <Icon icon={`mi:close`} width={24} className="text-primary" />
                    }
                </button>
            </div>
            <Transition
                show={showMobileMenu}
                as={'div'}
                enter="ease-out duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="bigLg:block hidden bg-primary">
                    <ul className="pt-12 pb-16 text-right pr-8">
                        <li className="mb-8">
                            <a href="/#o-inwestycji" type="button" onClick={() => setShowMobileMenu(false)} title='O inwestycji' className='tracking-a text-[32px] font-extrabold leading-label uppercase text-white'>
                                O inwestycji
                            </a>
                        </li>
                        <li className="mb-8">
                            <Link href={route('frontend.apartments')} title='Galeria' className='tracking-a text-[32px] font-extrabold leading-label uppercase text-white'>
                                Mieszkania
                            </Link>
                        </li>
                        <li className="mb-8">
                            <a href="/#lokalizacja" type="button" onClick={() => handleHrefLink('lokalizacja')} title='Lokalizacja' className='tracking-a text-[32px] font-extrabold leading-label uppercase text-white'>
                                Lokalizacja
                            </a>
                        </li>
                        <li className="mb-8">
                            <Link href={route('frontend.gallery')} title='Galeria' className='tracking-a text-[32px] font-extrabold leading-label uppercase text-white'>
                                Galeria
                            </Link>
                        </li>
                        <li className="mb-8">
                            <Link href={route('frontend.creditService')} title='Finansowanie' className='tracking-a text-[32px] font-extrabold leading-label uppercase text-white'>
                                Finansowanie
                            </Link>
                        </li>
                        <li className="mb-8">
                            <Link href={route('frontend.interiors')} title='Galeria' className='tracking-a text-[32px] font-extrabold leading-label uppercase text-white'>
                                Wnętrza
                            </Link>
                        </li>
                        <li className="mb-8">
                            <Link href={route('frontend.news')} title='Galeria' className='tracking-a text-[32px] font-extrabold leading-label uppercase text-white'>
                                Aktualności
                            </Link>
                        </li>
                        <li>
                            <button type="button" onClick={() => handleHrefLink('kontakt')} title='Galeria' className='tracking-a text-[32px] font-extrabold leading-label uppercase text-white'>
                                Kontakt
                            </button>
                        </li>
                    </ul>
                </div>
            </Transition>
        </header>
    )
}

export default Header;