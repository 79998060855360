import { ReactNode, useEffect, useMemo, useState } from "react"
import Footer from "./Partials/Footer"
import Header from "./Partials/Header"
import { Head, router } from "@inertiajs/react"
import useIsServer from "@/Hooks/useIsServer";

const FrontLayout = ({ children }: { children: ReactNode }) => {
    const [transitioning, setTransitioning] = useState<boolean>(false)
    const isServer = useIsServer();

    useEffect(() => {
        if (!isServer) {
            router.on('start', () => setTransitioning(true))
            router.on('finish', () => setTransitioning(false))
            router.on('navigate', (event) => {
                // @ts-ignore
                gtag("js", new Date());
                // @ts-ignore
                gtag('event', 'page_view', {
                    page_location: event.detail.page.url,
                    page_title: event.detail.page.component,
                });
            })
        }
    }, [])


    return (
        <>
            <Header />
            <main>
                {children}
            </main>
            <Footer />
        </>
    )
}

export default FrontLayout